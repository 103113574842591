html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

img,
video {
  max-width: 100%;
  max-height: 100%;
}
